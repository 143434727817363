import { useEffect, useState } from 'react';
import { getCRMMandates } from 'utils/firebase/cloud-functions';
import { MarketData } from '.';
import { Mandate as CRMMandate } from '../../../../functions/src/types/crm';

export type Mandate = CRMMandate & MarketData;

const mapCRMData = (data: CRMMandate): Mandate => {
  return {
    ...data,
    portfolio1: data.aze_PortfolioId,
    portfolio2: data.aze_Portfolio2Id,
    marketSegment: data.aze_MarketSegmentId,
  };
};

export const useCRMMandates = (principalPri: string): Mandate[] => {
  const [mandates, setMandates] = useState<Mandate[]>([]);

  const getMandatesForPrincipal = async (
    principalPri: string,
  ): Promise<CRMMandate[]> =>
    (
      await getCRMMandates({
        principalPri,
      })
    ).data;

  useEffect(() => {
    if (!principalPri?.length) return;

    setMandates([]);

    getMandatesForPrincipal(principalPri).then((retrievedMandates) => {
      setMandates(retrievedMandates.map(mapCRMData));
    });
  }, [principalPri]);

  return mandates;
};
