import { Icon, IconName } from 'components/Icon';
import React from 'react';

export type BadgeProps = {
  icon?: IconName;
  color?: 'violet-300' | 'black' | 'white';
  text: string;
};

export const Badge: React.FC<BadgeProps> = ({
  icon = 'Premium',
  color = 'violet-300',
  text,
}) => {
  const COLOR_CLASSES = {
    'violet-300': 'text-violet-300',
    black: 'text-black',
    white: 'text-white',
  }[color];

  return (
    <div className={`${COLOR_CLASSES} inline-flex items-center`}>
      {icon && (
        <span data-testid="link-icon" className="inline-block shrink-0">
          <Icon name={icon} inline />
        </span>
      )}
      <span className="text-2xs current font-intent ml-1 uppercase tracking-wider">
        {text}
      </span>
    </div>
  );
};
