import { CountryList } from 'components/CountryList';
import { CountryListItem } from 'components/CountryList/CountryList';
import { CountryTile } from 'components/CountryTile';
import { Heading } from 'components/Heading';
import { Mandate as MandateType } from 'hooks/queries/useCRMMandates';
import { MarketSegmentDefinitions } from 'hooks/queries/useMarketSegmentDefinitions';
import { getPortfoliosWithIds } from 'lib/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';

type CountryModalMandateProps = {
  mandates: MandateType[];
  selectedPrincipal: Principal;
  selectedCountry: string | undefined;
  marketSegmentDefinitions: MarketSegmentDefinitions;
};

const mandatesToCountryListItems = (
  mandates: MandateType[],
  marketSegmentDefinitions: MarketSegmentDefinitions,
): CountryListItem[] => {
  const marketSegmentIds = [
    ...new Set(mandates.map((mandate) => mandate.aze_MarketSegmentId.aze_code)),
  ];

  return marketSegmentIds
    .map((id) => {
      const def = marketSegmentDefinitions[id];
      if (def) {
        return {
          displayText: def.name,
          icon: def.icon,
        };
      }
      return false;
    })
    .filter(Boolean) as CountryListItem[];
};

export const Mandate: React.FC<CountryModalMandateProps> = ({
  mandates,
  marketSegmentDefinitions,
  selectedPrincipal,
  selectedCountry,
}: CountryModalMandateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        level="h3"
        text={t('modals:countryModal:principalAndAzelisInCountry', {
          selectedPrincipal: selectedPrincipal.manufacturerName,
          selectedCountry,
        })}
      />
      <p className="mb-3">{t('modals:countryModal:mandateBlurb')}</p>
      <CountryTile headline={t(`modals:countryModal:representedPortfolios`)}>
        <CountryList
          items={[
            ...new Set(
              mandates
                .map((mandate) =>
                  getPortfoliosWithIds(
                    mandate,
                    selectedPrincipal.portfolioLevel,
                  ),
                )
                .map((portfolio) => portfolio.name),
            ),
          ].map((displayText) => ({ displayText }))}
        />
      </CountryTile>
      <CountryTile
        headline={t(`modals:countryModal:representedMarketSegments`)}
      >
        <CountryList
          items={mandatesToCountryListItems(mandates, marketSegmentDefinitions)}
        />
      </CountryTile>
    </>
  );
};
