import classNames from 'classnames';
import React, { SyntheticEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, IconName } from '../Icon';

type Variant = 'default' | 'grey' | 'compact' | 'intent';

export type LinkProps = {
  /**
   * Label of the link
   * We only want to use this type of link for text, so we are not using the children prop
   */
  label?: string;
  /**
   * reference of the link
   */
  href?: string;
  /**
   * Target of the link
   */
  target?: string;
  /**
   * Variant to define the link style
   */
  variant?: Variant;
  /**
   * The icon to display before the link
   */
  icon?: IconName;
  /**
   * Should the icon be displayed left or right of the label
   */
  iconPosition?: 'left' | 'right';
  /**
   * Click action of the link
   */
  onClick?(event: SyntheticEvent): void;
  /**
   * Flag to disable link
   */
  disabled?: boolean;
  /**
   * Set Link inline
   */
  inline?: boolean;
  type?: 'anchor' | 'router';
  buttonClassName?: string;
  iconClassName?: string;
};

const BASE_LINK_CLASSES = 'font-sans flex flex-row';
const standard = `${BASE_LINK_CLASSES} font-medium text-blue-200 hover:text-blue-100 hover:underline cursor-pointer`;
const grey = `${BASE_LINK_CLASSES} font-medium text-grey-600 hover:text-blue-100 hover:underline cursor-pointer`;
const compact = `${BASE_LINK_CLASSES} text-xs text-blue-200 hover:text-blue-100 hover:underline cursor-pointer`;
const intent = `${BASE_LINK_CLASSES} font-intent tracking-wide text-xs text-blue-200 hover:text-blue-100 hover:underline cursor-pointer`;
const DISABLED_LINK_CLASSES = `${BASE_LINK_CLASSES} text-grey-400 hover:text-grey-400 cursor-default foo`;

export const Link: React.FC<LinkProps> = ({
  label,
  href,
  target,
  variant = 'default',
  icon,
  // Icons should be placed on the left by default
  iconPosition = 'left',
  inline,
  onClick,
  disabled,
  type = 'anchor',
  buttonClassName,
  iconClassName,
}: LinkProps) => {
  const onClickHandler = (event: SyntheticEvent) => {
    if (disabled) {
      if (event) {
        event.preventDefault();
      }
      return false;
    } else {
      if (onClick) {
        onClick(event);
      } else {
        return true;
      }
    }
  };
  const iconElementWidth = () => {
    switch (variant) {
      case 'compact':
        return '2';
      case 'intent':
        return '3';
      default:
        return '4';
    }
  };

  const variant1 = {
    default: standard,
    grey,
    compact,
    intent,
  };

  const attributes = {
    className: classNames({
      [variant1[variant] || '']: !disabled,
      [`${
        variant === 'compact' ? 'text-2xs' : 'font-medium'
      } ${DISABLED_LINK_CLASSES}`]: disabled,
      'inline-flex': inline,
      'flex-row-reverse': iconPosition === 'right' && icon,
    }),
    onClick: onClickHandler,
  };

  const content = (
    <>
      {icon && (
        <span
          data-testid="link-icon"
          className={`m${
            // Whichever side the icon is positioned, put margin on the opposite side to
            // push it away from the label. We don't need to check if iconPosition exists
            // because it's 'left' by default.
            iconPosition === 'left' ? 'r' : 'l'
          }-1 inline-block w-${iconElementWidth()} shrink-0`}
        >
          <Icon name={icon} inline={true} className={iconClassName} />
        </span>
      )}
      <span
        className={`${classNames({ 'mt-2px break-all': !!icon })} ${
          buttonClassName || ''
        }
        `}
      >
        {label}
      </span>
    </>
  );

  return type === 'anchor' ? (
    <a
      {...attributes}
      href={href}
      role={href ? 'link' : 'button'}
      target={target}
    >
      {content}
    </a>
  ) : (
    <RouterLink {...attributes} to={href ?? '/'}>
      {content}
    </RouterLink>
  );
};
