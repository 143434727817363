import { useEffect, useState } from 'react';
import { getCRMCountriesData } from 'utils/firebase/cloud-functions';

export type CRMCountryData = {
  id: number;
  region: string | undefined;
  cluster: string | undefined;
  country: { name: string | undefined; ISO3: string | undefined };
  staffCount: number;
  intro: string | undefined;
  offices: string[];
  managingDirector: {
    name: string | undefined;
    email: string | undefined;
  };
  marketSegments: { name: string; code: string; id: string; lab: boolean }[];
  servedBy?: {
    aze_countryid: string;
    aze_isocode: string;
    aze_name: string;
  };
  extraMessage?: string;
};
export type CRMCountryDataReturn = {
  crmCountriesData: CRMCountryData[];
  azelisCountriesCodes: CountryWithColor[];
  isLoading: boolean;
};

export type CountryWithColor = {
  ISO: string;
  colorGroup: {
    name: string;
    color: string;
  };
};

export const useCRMCountriesData = (): CRMCountryDataReturn => {
  const [crmCountriesData, setCrmCountriesData] = useState<CRMCountryData[]>(
    [],
  );
  const [azelisCountriesCodes, setAzelisCountriesCodes] = useState<
    CountryWithColor[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCRMCountriesData()
      .then((newData) => {
        const newIsoCodes: CountryWithColor[] = newData.data.map((country) => {
          return {
            ISO: country.country.ISO3 || '',
            colorGroup: { name: 'Azelis countries', color: 'bg-blue-240' },
          };
        });

        setCrmCountriesData(newData.data);
        setAzelisCountriesCodes(newIsoCodes);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error('I am failing', err);
      });
  }, []);

  return { crmCountriesData, azelisCountriesCodes, isLoading };
};
