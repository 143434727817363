import { useMemo } from 'react';
import { MarketSegment } from 'utils/firebase/collection';
import { useCollection } from 'utils/firebase/hooks/useCollection';

export type MarketSegmentDefinitions = {
  [key: string]: MarketSegment;
};

export const useMarketSegmentDefinitions = (): MarketSegmentDefinitions => {
  const collection = useCollection('market_segments');
  return useMemo(() => {
    //Temp hack should be fixed to make all dependancies work with undefined
    return (collection || []).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.data,
      }),
      {} as MarketSegmentDefinitions,
    );
  }, [collection]);
};
