import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDropdownContext } from '../Dropdown';
import styles from '../helper/dropdown.module.css';

export type DropdownItemProps = {
  disabled?: boolean;
  active?: boolean;
  onClick?: ({ ...args }) => void;
  value: string | number;
  name?: string;
  isParent?: boolean;
  closeOnSelect?: boolean;
  children?: React.ReactNode;
};

export const DropdownItem: React.FC<DropdownItemProps> = ({
  children,
  disabled,
  onClick,
  active,
  name,
  closeOnSelect = true,
  isParent,
}) => {
  const { setOpen } = useDropdownContext();

  useEffect(() => {
    if (active && closeOnSelect) setOpen(false);
  }, [active, closeOnSelect, setOpen]);

  return (
    <li
      className="w-full"
      data-testid={`dropdown-${isParent ? 'parent-' : ''}item`}
      tabIndex={-1}
    >
      <div
        onClick={disabled ? undefined : onClick}
        key={name}
        className={classNames(
          styles.item,
          'flex w-full justify-between p-2 text-left font-normal focus:text-blue-100 focus:outline-none',
          { 'text-grey-400 pointer-events-none': disabled },
          { 'text-grey-700 cursor-pointer': !disabled },
          { 'text-blue-100': active },
          { 'hover:text-blue-100': !isParent },
        )}
      >
        {isParent ? <ul tabIndex={-1}>{children}</ul> : children}
      </div>
    </li>
  );
};
