import { orderBy, QueryConstraint, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { MarketInsight } from 'utils/firebase/collection';
import { useCollection } from 'utils/firebase/hooks/useCollection';
import { useCurrentPrincipal, useCurrentUser } from './';

export function useMarketInsights(): {
  data: MarketInsight[];
  isLoading: boolean;
} {
  const { user } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);

  const byPrincipalId = useMemo<QueryConstraint[]>(() => {
    const constraints = [orderBy('publicationDate', 'desc')];
    return [...constraints, where('principalId', '==', currentPrincipal.id)];
  }, [currentPrincipal]);

  const collection = useCollection('marketinsights', byPrincipalId);
  const data = (collection || []).map(({ data, id }) => ({ ...data, id }));
  return { data, isLoading: !collection };
}
