import classNames from 'classnames';
import React from 'react';

export type ButtonProps = {
  label?: string;
  buttonType?: ButtonType;
  allowHover?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export enum ButtonType {
  NORMAL = 'normal',
  WHITE = 'white',
  ICON = 'icon',
}

const getButton = (buttonType: ButtonType = ButtonType.NORMAL) =>
  ({
    [ButtonType.NORMAL]: NormalButton,
    [ButtonType.WHITE]: WhiteButton,
    [ButtonType.ICON]: IconButton,
  }[buttonType]);

const BaseButton: React.FC<ButtonProps> = ({
  label,
  children,
  className,
  ...properties
}) => (
  <button
    className={`min-h-48px leading-2 disabled:text-grey-500 disabled:border-grey-200 disabled:bg-grey-200 rounded-full border text-center font-sans text-base font-normal focus:outline-none disabled:cursor-not-allowed ${
      className || ''
    }`}
    {...properties}
  >
    {label}
    {children}
  </button>
);

const NormalButton: React.FC<ButtonProps> = ({ className, ...props }) => (
  <BaseButton
    className={`px-7b border-action bg-action hover:bg-hover focus:bg-hover hover:border-hover focus:border-hover text-white ${
      className || ''
    }`}
    {...props}
  />
);

const WhiteButton: React.FC<ButtonProps> = ({ className, ...props }) => (
  <BaseButton
    className={`px-7b text-action border-grey-200 hover:border-grey-300 focus:border-grey-300 bg-white hover:bg-white ${
      className || ''
    }`}
    {...props}
  />
);

const IconButton: React.FC<ButtonProps> = ({
  allowHover = true,
  className,
  ...props
}) => (
  <BaseButton
    className={classNames(
      `${className} border-action bg-action px-2 text-white`,
      {
        'hover:border-grey-300 focus:border-grey-300 hover:bg-hover focus:bg-hover':
          allowHover,
      },
    )}
    {...props}
  />
);

export const Button: React.FC<ButtonProps> = ({
  buttonType,
  ...properties
}) => {
  const CustomButton = getButton(buttonType);
  return <CustomButton {...properties} />;
};
