import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

export type Level = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type Color = 'dark' | 'light';
export type Alignment = 'left' | 'center' | 'right';
export type Weight = 'bold' | 'semibold' | 'medium';
export type Margin = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

type LevelAlignmentDefinition = {
  [key: string]: Alignment[];
};

export type HeadingProps = {
  /**
   * Text of the button
   */
  text?: string;
  /**
   * Heading level
   */
  level?: Level;
  /**
   * Heading alignment
   */
  align?: Alignment;
  /**
   * Color of heading
   */
  color?: Color;
  /**
   * Bold or semi bold
   */
  weight?: Weight;
  /**
   * Flag to display optional field text
   */
  optionalField?: boolean;
  /**
   * Flag to remove margin
   */
  margin?: Margin;
  /**
   * Should this headline be truncated?
   */
  truncate?: boolean;
  /**
   * Should this break long words?
   */
  breakWords?: boolean;
};

export const Heading: React.FC<HeadingProps> = ({
  text = 'Headline',
  level = 'h1',
  align = 'left',
  color = 'dark',
  weight,
  optionalField = false,
  margin,
  truncate,
  breakWords,
}: HeadingProps) => {
  // As only certain alignments are allowed for each level, we check here if its a vald one
  const wrongAlignment = useMemo(() => {
    const forbiddenAlignments: LevelAlignmentDefinition = {
      h1: ['right'],
      h2: ['right'],
      h3: [],
      h4: [],
      h5: ['right'],
      h6: [],
    };
    return forbiddenAlignments[level].includes(align);
  }, [level, align]);

  const { t } = useTranslation();

  const className = useMemo(() => {
    // Handle color
    const colorClasses = color === 'dark' ? 'text-grey-700' : 'text-white';

    // Handle level
    let levelClasses = '';
    switch (level) {
      case 'h1':
        levelClasses = `font-serif font-${
          weight || 'bold'
        } text-4xl md:text-6xl leading-8 md:leading-10${
          typeof margin !== 'undefined' ? ` mb-${margin}` : ' mb-4'
        }`;
        break;
      case 'h2':
        levelClasses = `font-sans font-${
          weight || 'semibold'
        } text-3xl md:text-5xl leading-7 md:leading-9${
          typeof margin !== 'undefined' ? ` mb-${margin}` : ' mb-3'
        }`;
        break;
      case 'h3':
        levelClasses = `font-sans font-${
          weight || 'semibold'
        } text-xl md:text-2xl leading-5 md:leading-6${
          typeof margin !== 'undefined' ? ` mb-${margin}` : ' mb-3'
        }`;
        break;
      case 'h4':
        levelClasses = `font-sans font-${
          weight || 'semibold'
        } text-base leading-4${
          typeof margin !== 'undefined' ? ` mb-${margin}` : ' mb-1'
        }`;
        break;
      case 'h5':
        levelClasses = `font-serif font-${
          weight || 'bold'
        } text-base leading-4`;
        break;
      case 'h6':
        levelClasses = `font-sans font-${
          weight || 'semibold'
        } text-xs leading-3`;
        break;
    }

    // Handle alignment
    let alignmentClasses = '';
    switch (align) {
      case 'left':
        alignmentClasses = wrongAlignment ? '' : 'text-left';
        break;
      case 'center':
        alignmentClasses = wrongAlignment ? '' : 'text-center';
        break;
      case 'right':
        alignmentClasses = wrongAlignment ? '' : 'text-right';
        break;
    }

    const truncateClass = truncate ? 'truncate' : '';
    const breakWordsClass = breakWords ? 'break-words' : '';

    return `${colorClasses} ${levelClasses} ${alignmentClasses} ${truncateClass}${breakWordsClass}`.trim();
  }, [
    level,
    color,
    align,
    margin,
    weight,
    wrongAlignment,
    truncate,
    breakWords,
  ]);
  // Testing bitbucket settings
  const CustomHTag: Level = level;
  return (
    <CustomHTag className={className}>
      {text}
      {optionalField && (
        <span className="font-thin"> ({t('labels:optional')})</span>
      )}
    </CustomHTag>
  );
};
