import { useEffect, useState } from 'react';
import { getCRMExpansionOpportunities } from 'utils/firebase/cloud-functions';
import { MarketData } from '.';
import { ExpansionOpportunity as CRMExpansionOpportunity } from '../../../../functions/src/types/crm';

type FormattedValues = {
  createdby: string | undefined;
  salesteamstrength: string | undefined;
  portfoliofit: string | undefined;
  labsupportresources: string | undefined;
};

export type ExpansionOpportunity = CRMExpansionOpportunity &
  FormattedValues &
  MarketData;

const mapCRMData = (data: CRMExpansionOpportunity): ExpansionOpportunity => {
  return {
    ...data,
    portfolio1: data.aze_portfolio,
    portfolio2: data.aze_portfolio2,
    marketSegment: data.aze_marketsegmentid,
    createdby:
      data['_createdby_value@OData.Community.Display.V1.FormattedValue'],
    salesteamstrength:
      data['aze_salesteamstrength@OData.Community.Display.V1.FormattedValue'],
    portfoliofit:
      data['aze_portfoliofit@OData.Community.Display.V1.FormattedValue'],
    labsupportresources:
      data['aze_labsupportresources@OData.Community.Display.V1.FormattedValue'],
  };
};

const getExpansionOpportunitiesForPrincipal = async (
  principalPri: string,
): Promise<ExpansionOpportunity[]> =>
  principalPri
    ? (
        await getCRMExpansionOpportunities({
          principalPri,
        })
      ).data
    : [];

export const useExpansionOpportunities = (
  selectedPrincipalPri: string,
): ExpansionOpportunity[] | undefined => {
  const [expansionOpportunities, setExpansionOpportunities] =
    useState<ExpansionOpportunity[]>();

  useEffect(() => {
    setExpansionOpportunities(undefined);
    getExpansionOpportunitiesForPrincipal(selectedPrincipalPri).then((data) => {
      setExpansionOpportunities(data.map(mapCRMData));
    });
  }, [selectedPrincipalPri]);

  return expansionOpportunities;
};
