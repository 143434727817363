import classNames from 'classnames';
import { Icon, IconName } from 'components/Icon';
import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type InputProps = {
  /**
   * Value of the input
   */
  initialValue?: string | number | null;
  /**
   * Placeholder of the input
   */
  placeholder?: string;
  /**
   * Flag to disable input
   */
  disabled?: boolean;
  /**
   * Flag for error
   */
  error?: boolean;
  /**
   * Type of input
   */
  type?: 'text' | 'number' | 'password' | 'email';
  /**
   * Maximum length for input
   */
  maxLength?: number;
  /**
   * Variant of input
   */
  variant?: 'default' | 'transparent' | 'dropdown-filter';
  /**
   * Font weight for input
   */
  fontWeight?: string;
  /**
   * Autofocus flag
   */
  autofocus?: boolean;
  /**
   * Autosuggest setting
   */
  autoComplete?: string;
  /**
   * Icon to be displayed within the input
   */
  icon?: IconName;
  /**
   * Icon to be displayed within the input
   */
  id?: string;
  /**
   * Icon to be displayed within the input
   */
  dataTestid?: string;
  /**
   * Which side should the icon be positioned?
   */
  iconPosition?: 'left' | 'right';
  /**
   * Change action of the input
   */
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  /**
   * Focus action of the input
   */
  onFocus?(): void;
  /**
   * Blur action of the input
   */
  onBlur?(event: ChangeEvent<HTMLInputElement>): void;
  /**
   * On key down action of the input
   */
  onKeyDown?(event: KeyboardEvent<HTMLInputElement>): void;
};

const BASE_INPUT_CLASSES = 'outline-none w-full';
const DEFAULT_INPUT_CLASSES = `${BASE_INPUT_CLASSES} placeholder-grey-600 text-grey-700 hover:border-blue-100 focus:border-blue-100`;
const DISABLED_INPUT_CLASSES = `${BASE_INPUT_CLASSES} border-grey-200 placeholder-grey-400 text-grey-400`;
const ERROR_INPUT_CLASSES = `${BASE_INPUT_CLASSES} border-red-200 placeholder-grey-600 text-grey-700 hover:border-blue-100 focus:border-blue-100`;

export const Input: React.FC<InputProps> = ({
  initialValue,
  placeholder,
  disabled,
  error,
  type,
  maxLength,
  variant,
  autofocus,
  autoComplete,
  fontWeight = 'font-normal',
  icon,
  iconPosition,
  id,
  dataTestid,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
}: InputProps) => {
  const [value, setValue] = useState(initialValue);
  const className = useMemo(
    () =>
      [
        ...(function* () {
          if (disabled) {
            yield DISABLED_INPUT_CLASSES;
          } else if (error) {
            yield ERROR_INPUT_CLASSES;
          } else {
            yield DEFAULT_INPUT_CLASSES;
          }

          switch (variant) {
            case 'transparent':
              yield 'border-grey-700 bg-transparent border-b-2';
              break;
            case 'dropdown-filter':
              yield 'bg-white border-grey-500 border-b p-2';
              break;
            case 'default':
            default:
              yield 'border-grey-500 bg-white border rounded p-2b';
              break;
          }
          yield fontWeight;
          if (iconPosition === 'left') {
            yield 'pl-6';
          }
          if (iconPosition === 'right') {
            yield 'pr-6';
          }
        })(),
      ].join(' '),
    [disabled, error, variant, fontWeight, iconPosition],
  );
  const iconElement = icon && (
    <div
      className={classNames('w-20px top-15px text-grey-600 absolute', {
        'right-15px': iconPosition === 'right',
        'left-15px': iconPosition === 'left',
      })}
    >
      <Icon name={icon} />
    </div>
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="relative">
      <input
        data-testid={dataTestid}
        id={id}
        value={value || ''}
        className={`w-inherit ${className}`}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        step="any"
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        autoFocus={autofocus}
        autoComplete={autoComplete}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
          if (onChange) {
            onChange(event);
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {iconElement}
    </div>
  );
};
