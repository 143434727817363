import { DropdownFilter } from 'components/Dropdown/DropdownVariants/DropdownFilter';
import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { LoadingBounce } from 'components/Loading';
import { orderBy, uniqBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Regions, countriesMappedByRegion } from 'utils/countries';
import { EmptyState } from '../../components/EmptyState';
import { MarketInsight } from '../../components/MarketInsight';
import { useCRMMarketInsights, useMarketInsights } from '../../hooks/queries';

type MarketInsightsTabProps = {
  selectedPrincipal: string;
};

type MarketInsightFilters = {
  selectedCountryRegions: DropdownOptionProps[] | undefined;
  selectedMarketSegments: DropdownOptionProps[] | undefined;
  sortingOrder: 'asc' | 'desc' | undefined;
};

export const MarketInsightsTab: React.FC<MarketInsightsTabProps> = ({
  selectedPrincipal,
}: MarketInsightsTabProps) => {
  const { t } = useTranslation();

  const { data: crmMarketInsights, isLoading: isCrmMarketInsightsLoading } =
    useCRMMarketInsights(selectedPrincipal);

  const {
    data: firebaseMarketInsights,
    isLoading: isFirebaseMarketInsightsLoading,
  } = useMarketInsights();

  const [marketInsightFilters, setMarketInsightsFilters] =
    useState<MarketInsightFilters>({
      selectedCountryRegions: undefined,
      selectedMarketSegments: undefined,
      sortingOrder: undefined,
    });

  const { selectedCountryRegions, selectedMarketSegments, sortingOrder } =
    marketInsightFilters;

  const marketInsights = useMemo(
    () => crmMarketInsights.concat(firebaseMarketInsights),
    [crmMarketInsights, firebaseMarketInsights],
  );

  const countriesFromMarketInsights = new Set(
    marketInsights.map(({ countries }) => countries).flat(),
  );

  const countriesGroupedByRegions: Regions = countriesMappedByRegion(
    [...countriesFromMarketInsights].map((c) => ({
      name: c,
      ISO_A3: '',
      ISO_A2: '',
    })),
  );

  const regionCountryOptions: DropdownOptionProps[] = Object.entries(
    countriesGroupedByRegions,
  ).map(([region, countries]) => ({
    value: region,
    name: region,
    childOptions: countries.map(({ name }) => ({
      name,
      value: name,
    })),
  }));

  const availableMarketSegmentOptions = useMemo<DropdownOptionProps[]>(() => {
    const marketSegments = marketInsights.map(
      ({ marketSegment }) => marketSegment,
    );

    const uniqueMarketSegments = uniqBy(marketSegments, 'value').filter(
      (marketSegment) => !!marketSegment.value,
    );

    return uniqueMarketSegments.map(({ name, value }) => ({
      name,
      value,
    }));
  }, [marketInsights]);

  const filteredSortedMarketInsights = useMemo(() => {
    const selectedMarketSegmentFilterValues = (
      selectedMarketSegments || availableMarketSegmentOptions
    ).map((option) => option.value);

    const selectedCountryValues = selectedCountryRegions?.map(
      (option) => option.name,
    );

    const selectedRegionValues = regionCountryOptions
      .map((option) => {
        if (
          selectedCountryValues?.includes(option.name) ||
          option.childOptions?.some(({ name }) =>
            selectedCountryValues?.includes(name),
          )
        ) {
          return option.name;
        }
      })
      .filter(Boolean);

    const filteredMarketInsights = marketInsights.filter(
      ({ countries, marketSegment, region }) => {
        // Check if market segment (with ID) is selected
        const hasMarketSegmentSelected =
          selectedMarketSegmentFilterValues.includes(marketSegment.value);

        // Check if market segment has no ID, so then it should always show as it has no filterable value,
        // assuming this could be seen as a 'global' market insight
        const hasGeneralMarketSegmentSelected = marketSegment.value === '';

        // Combine the checks for market segments
        const marketSegmentFilter =
          hasMarketSegmentSelected || hasGeneralMarketSegmentSelected;

        // If no filter for country is selected, show insight
        const noDefaultSelected = !selectedCountryValues;

        // As some kind of fallback/hack, check if all options are selected so Global also shows
        const hasAllCountriesOrRegionsSelected =
          countriesFromMarketInsights.size === selectedCountryValues?.length;

        // Check if the market insight has a region, and if the option is selected, show the market insight
        const hasRegionSelected =
          region &&
          (selectedCountryValues?.includes(region) ||
            selectedRegionValues.includes(region));

        // Check if the market insight countries match one of the selected countries
        const hasCountrySelected = countries.some((country) =>
          selectedCountryValues?.includes(country),
        );

        // Combine the checks for countries
        const countryFilter =
          noDefaultSelected ||
          hasAllCountriesOrRegionsSelected ||
          hasRegionSelected ||
          hasCountrySelected;

        // Combine the filters for market segments and countries
        return marketSegmentFilter && countryFilter;
      },
    );

    const filteredAndSortedMarketInsights = orderBy(
      filteredMarketInsights,
      'publicationDate',
      sortingOrder,
    );

    return filteredAndSortedMarketInsights;
  }, [
    availableMarketSegmentOptions,
    countriesFromMarketInsights.size,
    marketInsights,
    regionCountryOptions,
    selectedCountryRegions,
    selectedMarketSegments,
    sortingOrder,
  ]);

  const handleDropdownOptionsChange = useCallback(
    (
      options: string | DropdownOptionProps[],
      type: keyof MarketInsightFilters,
    ) => {
      if (options === marketInsightFilters[type]) return;
      setMarketInsightsFilters((current) => ({
        ...current,
        [type]: options,
      }));
    },
    [marketInsightFilters],
  );

  const isLoading =
    isCrmMarketInsightsLoading || isFirebaseMarketInsightsLoading;

  return (
    <>
      <div className="mb-5 pt-6 md:mb-6 md:pt-7">
        <div className="flex flex-col justify-end md:flex-row md:space-x-2">
          {regionCountryOptions.length > 0 && (
            <DropdownFilter
              typeAhead
              label={t(
                'features:market-intelligence:marketInsights:filters:country:label',
              )}
              selectAllLabel={t('placeholders:selectAll')}
              deselectAllLabel={t('placeholders:deselectAll')}
              options={regionCountryOptions}
              initialSelection={
                selectedCountryRegions ||
                regionCountryOptions
                  .map(({ childOptions }) => childOptions ?? [])
                  .flat()
              }
              onChange={(options: DropdownOptionProps[]) =>
                handleDropdownOptionsChange(options, 'selectedCountryRegions')
              }
            />
          )}
          <DropdownFilter
            typeAhead
            label={t(
              'features:market-intelligence:marketInsights:filters:marketSegment:label',
            )}
            selectAllLabel={t('placeholders:selectAll')}
            deselectAllLabel={t('placeholders:deselectAll')}
            options={availableMarketSegmentOptions}
            initialSelection={
              selectedMarketSegments || availableMarketSegmentOptions
            }
            widthVariant="full-width"
            onChange={(options: DropdownOptionProps[]) =>
              handleDropdownOptionsChange(options, 'selectedMarketSegments')
            }
          />
          <DropdownSingleSelect
            initialSelection={{
              name: t(
                `features:market-intelligence:marketInsights:filters:sort:label`,
                { context: sortingOrder },
              ),
              value: sortingOrder || 'desc',
            }}
            widthVariant="full-width"
            onChange={(
              selectedOption:
                | DropdownOptionProps
                | DropdownOptionProps[]
                | undefined,
            ) => {
              handleDropdownOptionsChange(
                (selectedOption as DropdownOptionProps).value as 'desc' | 'asc',
                'sortingOrder',
              );
            }}
            allowUnset={false}
            options={[
              {
                name: t(
                  `features:market-intelligence:marketInsights:filters:sort:label`,
                  { context: 'desc' },
                ),
                value: 'desc',
              },
              {
                name: t(
                  `features:market-intelligence:marketInsights:filters:sort:label`,
                  { context: 'asc' },
                ),
                value: 'asc',
              },
            ]}
          />
        </div>
      </div>
      <div className="mb-4 flex flex-wrap md:mb-5">
        {isLoading ? (
          <LoadingBounce text="Loading market insights" />
        ) : filteredSortedMarketInsights.length > 0 ? (
          filteredSortedMarketInsights.map((entry, index: number) => {
            return (
              <div
                className="w-full md:w-6/12 md:justify-center md:px-2"
                key={index}
              >
                <MarketInsight
                  categories={entry.categories}
                  content={entry.content}
                  countries={entry.countries}
                  region={entry.region}
                  publicationDate={entry.publicationDate}
                  entry={entry}
                  link={entry.link}
                  marketSegment={entry.marketSegment.name}
                  title={entry.title}
                />
              </div>
            );
          })
        ) : (
          <div className="mt-4 w-full">
            <EmptyState
              message={t('features:market-intelligence:marketInsights:empty')}
              hasBorder
            />
          </div>
        )}
      </div>
    </>
  );
};
