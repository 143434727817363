import { Mandate } from 'hooks/queries/useCRMMandates';
import { ExpansionOpportunity } from 'hooks/queries/useExpansionOpportunities';
import React from 'react';
import './styles.css';

interface DashboardTileProps {
  label: string;
  mandates: (Mandate | ExpansionOpportunity)[];
  property: string;
  isPortfolio2Principal?: boolean;
}

// obj = mandate, with all data
// originPath = the keys from mandate for values we want to extract
export const extractValue = (
  obj: never,
  originPath: string,
  isPortfolio2Principal?: boolean,
): string | number => {
  const processValues = (path: string) => {
    const pathElements: string[] = path.split('.'); // e.g. ["aze_PortfolioId", "aze_name"]
    for (const element of pathElements) {
      if (obj !== null && obj[element]) {
        obj = obj[element];
      }
    }
  };

  const hasTwoParameters = originPath.includes(' ');
  if (hasTwoParameters && isPortfolio2Principal) {
    const bothParameters = originPath.split(' '); // ["aze_PortfolioId.aze_name", "aze_Portfolio2Id.aze_name"]
    const bothParametersSplitOnDot = bothParameters.map((i) => i.split('.')); // [['aze_PortfolioId', 'aze_name'], ['aze_Portfolio2Id', 'aze_name']]
    let result = '';
    for (const part of bothParametersSplitOnDot) {
      if (obj?.[part[0]]?.[part[1]]) {
        result += `${obj[part[0]][part[1]]}`;
      }
    }
    return result;
  } else {
    processValues(originPath);
  }

  return obj;
};

export const DashboardTile: React.FC<DashboardTileProps> = ({
  label,
  mandates,
  property,
  isPortfolio2Principal,
}: DashboardTileProps) => {
  const current = new Set(
    mandates.map((m) =>
      extractValue(m as never, property, isPortfolio2Principal),
    ),
  ).size;

  return (
    <div className="rounded-4px border-grey-300 p-20px dashboardtile-container relative border border-solid">
      <h6 className="text-2xs font-sans uppercase tracking-wider">{label}</h6>
      <span className="dashboardtile-content absolute inline-block font-serif text-5xl font-semibold">
        {current}
      </span>
    </div>
  );
};
