import { Badge } from 'components/Badge';
import { Icon } from 'components/Icon';
import { LoadingBounce } from 'components/Loading';
import { getCountriesFromMandates } from 'components/MandateSelection/MandateSelection';
import { PermissionDenied } from 'components/PermissionDenied/PermissionDenied';
import {
  useCRMMandates,
  useCurrentPrincipal,
  useCurrentUser,
  useMarketSegmentDefinitions,
} from 'hooks/queries';
import { LocalStorageKeys, getFromLocalStorage } from 'lib/localStorageManager';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Permission } from 'shared/types/authorization';
import { getCustomerSupplierPages } from 'utils/firebase/cloud-functions';
import { Principal } from 'utils/firebase/collection';
import { Heading } from '../../components/Heading';
import { Tab, Tabs } from '../../components/Tabs';
import { routes } from '../../controllers/ContentController/Routes';
import { usePermission } from '../../hooks/usePermission';
import { CustomerPortalInsightsTab } from './CustomerPortalInsightsTab';
import {
  DaLocalizationConfig,
  DigitalActivitiesModal,
} from './DigitalActivitiesModal';
import { EmptyConfig } from './EmptyConfig';
import { LocalizationConfigDisplay } from './LocalizationConfigDisplay';
import { SupplierPagesTab } from './SupplierPagesTab';

export enum DigitalActivitiesTabSlugs {
  CUSTOMER_PORTAL_INSIGHTS = 'customer-portal-insights',
  SUPPLIER_PAGES = 'supplier-pages',
}

export type CustomerSuppplierPagesData = {
  code: string;
  links: CPLinkProps[];
};

export type CPLinkProps = {
  country: string;
  language: string;
  market_segment: string;
  market_segment_code: string;
  url: string;
};

export const getLinks = async (
  principal: Principal,
): Promise<CustomerSuppplierPagesData> => {
  return (await getCustomerSupplierPages({ principal })).data;
};

export const getChosenPageObj = (
  links: CPLinkProps[],
  localizationConfig: DaLocalizationConfig,
): CPLinkProps | undefined =>
  links.find(
    ({ country, market_segment_code, language }) =>
      country === localizationConfig.country &&
      market_segment_code === localizationConfig.marketSegmentId &&
      language === localizationConfig.language,
  );

export const DigitalActivities: React.FC = () => {
  const { tab: tabFromURL } = useParams<{ tab: string | undefined }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pagesData, setPagesData] = useState<CustomerSuppplierPagesData>();
  const { user } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>('');

  // This is used to detect if the user?.selectedPrincipal changes
  const [targetPrincipal, setTargetPrincipal] = useState<string | undefined>(
    undefined,
  );
  const mandates = useCRMMandates(currentPrincipal.principalPri);
  const countriesfromMandates = useMemo(
    () => getCountriesFromMandates(mandates || [], true),
    [mandates],
  );
  const { userHasPermission } = usePermission();

  const cookieResult: Record<string, DaLocalizationConfig> | null =
    getFromLocalStorage(LocalStorageKeys.DIGITAL_ACTIVITIES);

  const localizationConfig: DaLocalizationConfig | undefined =
    cookieResult && currentPrincipal.manufacturerName
      ? cookieResult[currentPrincipal.manufacturerName]
      : undefined;

  const [isConfigModalHidden, setIsConfigModalHidden] = useState(
    !!localizationConfig,
  );

  const userHasCustomerPortalViewPermission = userHasPermission(
    Permission.CUSTOMER_PORTAL_INSIGHTS_VIEW,
  );

  const portalHasCustomerPortalViewEnabled =
    currentPrincipal.settings.customer_portal_insights_view;

  const userHasSupplierPagesViewPermission = userHasPermission(
    Permission.SUPPLIER_PAGES_VIEW,
  );

  const marketSegmentDefinitions = useMarketSegmentDefinitions();
  const getMarketSemegentKeys = () =>
    Object.keys(marketSegmentDefinitions).filter((key) => {
      return marketSegmentDefinitions[key].customerPortalName
        ? marketSegmentDefinitions[key].customerPortalName ===
            localizationConfig?.marketSegmentId
        : marketSegmentDefinitions[key].name ===
            localizationConfig?.marketSegmentId;
    });

  // From CP we receive Market segment names that are not 100% in line with our market segment keys. In the case of a missmatch, we store the name that is used in CP in Firebase
  // With this function, we can get the market segment key that is globally
  // And overwrite the marketsegment section for creating the porwerBi filters

  const marketSegmentKeys =
    marketSegmentDefinitions && getMarketSemegentKeys()[0];
  // create a variable that has the localizationConfig but replace the market segment with the keys
  const localizationConfigWithKeys = localizationConfig && {
    ...localizationConfig,
    marketSegment: marketSegmentKeys,
  };

  // This needs to exist as a separate function (not just put the contents
  // in the useEffect as a block) because getLinks is async, and the usesEffect
  // parent function cannot be made async
  const getSupplierPageUrls = useCallback(async () => {
    // AZEPP-990 Switch to new endpoint of supplier page data (Staging)
    // Staging environment based logics should be removed and moved to solely using principalPri
    // after Azelis release

    if (
      (process.env.REACT_APP_ENVIRONMENT === 'staging' &&
        !currentPrincipal.principalPri) ||
      (process.env.REACT_APP_ENVIRONMENT !== 'staging' &&
        !currentPrincipal.manufacturerName)
    )
      return;

    setIsLoading(true);
    const res = await getLinks(currentPrincipal);

    if (res?.links.length) {
      setPagesData(res);

      if (!localizationConfig) {
        setIsConfigModalHidden(false);
      }
    } else {
      // If there are no links available for this principal,
      // show this to the user and clear the filteres stored
      setPagesData(undefined);
    }
    setTargetPrincipal(currentPrincipal.id);
    setIsLoading(false);
  }, [currentPrincipal, localizationConfig]);

  let fallbackTabToShowOnLoad;

  if (portalHasCustomerPortalViewEnabled && userHasCustomerPortalViewPermission)
    fallbackTabToShowOnLoad =
      DigitalActivitiesTabSlugs.CUSTOMER_PORTAL_INSIGHTS;
  else if (userHasSupplierPagesViewPermission)
    fallbackTabToShowOnLoad = DigitalActivitiesTabSlugs.SUPPLIER_PAGES;

  if (!selectedTab && (tabFromURL || fallbackTabToShowOnLoad))
    setSelectedTab(tabFromURL || fallbackTabToShowOnLoad || '');

  const userCanViewAtLeastOneTab =
    (portalHasCustomerPortalViewEnabled &&
      userHasCustomerPortalViewPermission) ||
    userHasSupplierPagesViewPermission;

  useEffect(() => {
    const { digitalActivitiesRoute } = routes(currentPrincipal.slug);
    navigate(`${digitalActivitiesRoute.url}/${selectedTab}`);
    setIsConfigModalHidden(true);
    // Because when we reach /digital-activities, there should always be a tab active
    // and the URL should reflect that.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    // Re-fetch supplier pages only if they haven't yet been loaded, or
    // if the selectedPrincipal changes
    if (!pagesData || currentPrincipal.id !== targetPrincipal) {
      getSupplierPageUrls();
    }
  }, [getSupplierPageUrls, pagesData, targetPrincipal, currentPrincipal]);

  const selectedPageObj: CPLinkProps | undefined =
    pagesData &&
    localizationConfig &&
    getChosenPageObj(pagesData.links, localizationConfig);

  const customerPortalInsights =
    DigitalActivitiesTabSlugs.CUSTOMER_PORTAL_INSIGHTS;

  const hasLinks = pagesData?.links?.length !== 0;

  return (
    <div className="w-full" data-testid="digital-activities">
      {userCanViewAtLeastOneTab ? (
        <div className="w-full px-4 md:px-6">
          <div className="max-w-xl-content mx-auto">
            <div className="mb-4 pt-6 md:pt-7" data-test="page-header">
              <div className="flex flex-col items-center justify-start gap-4 md:flex-row md:space-x-2">
                <Heading
                  text={`${t('features:digital-activities:heading')}`}
                  margin={0}
                  level="h1"
                />
                {selectedTab === customerPortalInsights ? (
                  <Badge text={t('components:visibilityBadge:text')} />
                ) : null}
              </div>
            </div>
            {isLoading || !mandates ? (
              <LoadingBounce />
            ) : pagesData?.code === currentPrincipal.principalPri ? (
              <Fragment>
                <LocalizationConfigDisplay
                  fullAvailableCountries={countriesfromMandates}
                  localizationConfig={localizationConfig}
                  onEditClick={() => {
                    setIsConfigModalHidden(false);
                  }}
                />
                {localizationConfig ? (
                  <Tabs activeTab={selectedTab} onTabChange={setSelectedTab}>
                    {portalHasCustomerPortalViewEnabled &&
                    userHasCustomerPortalViewPermission ? (
                      <Tab
                        tabKey={
                          DigitalActivitiesTabSlugs.CUSTOMER_PORTAL_INSIGHTS
                        }
                        title={t(
                          'features:digital-activities:digitalActivities:tab',
                        )}
                      >
                        {localizationConfigWithKeys && currentPrincipal && (
                          <CustomerPortalInsightsTab
                            principal={currentPrincipal.manufacturerName}
                            filters={localizationConfigWithKeys}
                          />
                        )}
                      </Tab>
                    ) : (
                      <Fragment />
                    )}
                    {userHasSupplierPagesViewPermission && hasLinks ? (
                      <Tab
                        tabKey={DigitalActivitiesTabSlugs.SUPPLIER_PAGES}
                        title={t(
                          'features:digital-activities:supplierPages:tab',
                        )}
                      >
                        <SupplierPagesTab
                          link={
                            selectedPageObj ? selectedPageObj?.url : undefined
                          }
                        />
                      </Tab>
                    ) : (
                      <Fragment />
                    )}
                  </Tabs>
                ) : (
                  <EmptyConfig
                    onLinkClick={() => {
                      setIsConfigModalHidden(false);
                    }}
                  />
                )}
                <DigitalActivitiesModal
                  supplierPageOptionData={pagesData?.links}
                  existingLocalizationConfig={localizationConfig}
                  // We could just use !!localizationConfig to check if isHidden
                  // BUT then we would have to clear localizationConfig in order to show
                  // the modal, and we don't want that
                  isHidden={isConfigModalHidden}
                  onClose={() => {
                    setIsConfigModalHidden(true);
                  }}
                  principal={currentPrincipal.manufacturerName}
                  countriesFromMandates={countriesfromMandates}
                />
              </Fragment>
            ) : (
              <div className="flex flex-col items-center">
                <Icon name="EmptyMarket" />
                <p className="mt-3">
                  {t('features:digital-activities:noAvailableData')}
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <PermissionDenied />
      )}
    </div>
  );
};
